import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { colorConfig } from 'src/environments/color-configs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/utils/auth.service';
import { ApiService } from '../../../api/api.service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Location } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: any;
  Version = environment.uiVersion;
  msgs: any = [];
  bgColor = '#f1772e';
  domain = 'snowpaths';
  backend='';
  backendVersion = '';
  GISType = '';
  appVersion:any;
  orgDetails: any;
  orgId='';
  isLoginPage=false;
  currentUrl = '';
  // constructor() { }
  constructor(private router: Router, private apiService: ApiService, private authService: AuthService,
    private location: Location) { }

  ngOnInit() {
    const urlOrigin = window.location.origin;
    var currentUrl = this.location.path();
    this.currentUrl = this.location.path();
    if(this.currentUrl.includes("login") || this.currentUrl.includes("forgot-password")){
      this.isLoginPage = true;
    }
    if(this.isLoginPage){
    let isAvailable = urlOrigin.includes("streetpaths");
    let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    if(isAvailable){
      this.domain='streetpaths';
    }
    if(isFleetpathAvailable){
      this.domain='fleetpaths';
    }
  }
  if(!this.isLoginPage){
    this.domain = this.apiService.getLocalStorage('domain');
  }
    this.handlePortalStyles();
    // this.envStyles=environment.envName;
    this.currentYear = (new Date()).getFullYear();
    if(this.authService.getAuthenticatedUser()){
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.apiService.setAuthResult(session);
        let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = tokenValue['custom:organizationid'];
        this.getOrgDetails();
      }
    });
  }
    this.backend = this.apiService.getLocalStorage('Backend');
    this.backendVersion = this.apiService.getLocalStorage('BackendVersion')
    // this.getUIReleaseVersion()
    // this.getVersions();
  }
  handlePortalStyles(){
    if(!this.isLoginPage){
      if(this.currentUrl.split('/').pop() !== 'Report'){
        this.domain=this.apiService.getLocalStorage('domain');
      } else {
        this.domain = this.apiService.domain;
      }
    }
    if(this.domain){
      this.bgColor = colorConfig[this.domain]['footerBackground'];
    }
  }
  async getOrgDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.orgDetails = data;
      if(data){
        this.GISType=data.organization['GIS'] === 1 ? 'G':'NG';
        if(data.organization['Lite'] === 1){
          this.appVersion = " Lite Version"
        }
        this.apiService.setAppVersion(this.appVersion);
      }
    }, (error) => {
      console.log(error);
    })
  }
  getDomain(){
    this.domain=this.apiService.getLocalStorage('domain');
    if(this.domain){
      this.handlePortalStyles();
    }
  }
}
