import { Injectable } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { User } from './user.model';
import {Subject} from 'rxjs';
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, CognitoUserSession, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/api/api.service';

const POOL_DATA = {

  UserPoolId: environment.userPoolId,
  ClientId: environment.appClientId
/*
  UserPoolId: 'us-east-1_RJiMLZGtH',
  ClientId: '2g6r1k80rlilfrc5g5p8c58odc'
  */

};

const userPool = new CognitoUserPool(POOL_DATA);

@Injectable()
export class AuthService {
  authIsLoading = new BehaviorSubject<boolean>(false);
  authDidFail = new BehaviorSubject<boolean>(false);
  authStatusChanged = new Subject<boolean>();
  registeredUser?: CognitoUser;
  userPoolConfigs:any;
  POOL_DATA = {

    UserPoolId: '',
    ClientId: ''
  /*
    UserPoolId: 'us-east-1_RJiMLZGtH',
    ClientId: '2g6r1k80rlilfrc5g5p8c58odc'
    */
  
  };
  userPool:any;
  domain='';

  constructor(private router: Router, private r: ActivatedRoute,
    private apiService: ApiService) {
    // this.getPoolConfigs();
    var upoolData = localStorage.getItem('poolData');
    if(upoolData){
      var poolData = JSON.parse(atob(upoolData));
    }
    var user = localStorage.getItem('username');
    if(user){

      this.domain = user.slice(
        user.indexOf('@') + 1,
        user.lastIndexOf('.'),
        ).toLowerCase();
      }
    // this.POOL_DATA.UserPoolId = poolData[domain]['userPoolId'];
    // this.POOL_DATA.ClientId = poolData[domain]['clientId']
  }
  // async getPoolConfigs() {
  //   var url = 'cognitoUserPool';
  //   await this.apiService.getByPublicUrl(url).subscribe((data: any) => {
  //     this.userPoolConfigs = data;
  //     var userData = JSON.stringify(data)
  //     POOL_DATA.UserPoolId = this.userPoolConfigs[this.domain]['userPoolId'];
  //   POOL_DATA.ClientId = this.userPoolConfigs[this.domain]['clientId']
  //     this.apiService.addLocalStorage("poolData",btoa(userData))
  //   }, (error) => {
  //     console.log(error);
  //   })
  // }
  // signUp(username: string, email: string, password: string): void {
  //   this.authIsLoading.next(true);
  //   const user: User = {
  //     username: username,
  //     email: email,
  //     password: password
  //   };
  //   const attrList: CognitoUserAttribute[] = [];
  //   const emailAttribute = {
  //     Name: 'email',
  //     Value: user.email
  //   };
  //   attrList.push(new CognitoUserAttribute(emailAttribute));
  //   userPool.signUp(user.username, user.password, attrList, null, (err: any, result: any) => {
  //     if (err) {
  //       this.authDidFail.next(true);
  //       this.authIsLoading.next(false);
  //       return;
  //     }
  //     this.authDidFail.next(false);
  //     this.authIsLoading.next(false);
  //     this.registeredUser = result.user;
  //   });
  //   return;
  // }
  confirmUser(username: string, code: string) {
    this.authIsLoading.next(true);
    this.userPool = new CognitoUserPool(this.POOL_DATA)
    const userData = {
      Username: username,
      Pool: this.userPool
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        this.authDidFail.next(true);
        this.authIsLoading.next(false);
        return;
      }
      this.authDidFail.next(false);
      this.authIsLoading.next(false);
      this.router.navigate(['/Admin/']);
    });
  }
  signIn(username: string, password: string): void {
    this.userPool = new CognitoUserPool(this.POOL_DATA)
    this.authIsLoading.next(true);
    const authData = {
      Username: username,
      Password: password
    };
    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: username,
      Pool: this.userPool
    };
    const cognitoUser = new CognitoUser(userData);
    const that = this;
    cognitoUser.authenticateUser(authDetails, {
      onSuccess(result: CognitoUserSession) {
        that.authStatusChanged.next(true);
        that.authDidFail.next(false);
        that.authIsLoading.next(false);
        // console.log(result);
        // this.router.navigate(['dashboard'], {relativeTo: this.r});
        // this.router.navigate(['/landing-page']);
      },
      onFailure(err) {
        that.authDidFail.next(true);
        that.authIsLoading.next(false);
      }
    });
    this.authStatusChanged.next(true);
    return;
  }
  getAuthenticatedUser() {
    var poolData:any;
    var pData = localStorage.getItem('poolData');
    if(pData){
      poolData = JSON.parse(atob(pData));
    }
    var user = localStorage.getItem('username');
    if(user){

      this.domain = user.slice(
        user.indexOf('@') + 1,
        user.lastIndexOf('.'),
        ).toLowerCase();
      }
      if(!this.domain ||!poolData || !poolData[this.domain]){
        localStorage.clear();
        return poolData
      // this.router.navigate(['/']);
      }else {
      if(poolData){
        POOL_DATA.UserPoolId = poolData[this.domain]['userPoolId'];
        POOL_DATA.ClientId = poolData[this.domain]['clientId']
      }
      if(this.userPoolConfigs){
        POOL_DATA.UserPoolId = this.userPoolConfigs[this.domain]['userPoolId'];
        POOL_DATA.ClientId = this.userPoolConfigs[this.domain]['clientId']
      }
    this.userPool = new CognitoUserPool(POOL_DATA)
    const userData = this.userPool.getCurrentUser();
    // console.log(userData)
    if(!userData || !this.domain){
      localStorage.clear();
      this.router.navigate(['/']);
    }
    return this.userPool.getCurrentUser();
  }
  }
  async logout() {
    this.getAuthenticatedUser().signOut();
    this.authStatusChanged.next(false);
  }
  isAuthenticated(): Observable<boolean> {
    const user = this.getAuthenticatedUser();
    const obs = Observable.create((observer: any) => {
      // @ts-ignore
      if (!user) {
        observer.next(false);
      } else {
        user.getSession((err: any, session: { isValid: () => any; }) => {
          if (err) {
            observer.next(false);
          } else {
            if (session.isValid()) {
              observer.next(true);
            } else {
              observer.next(false);
            }
          }
        });
      }
      observer.complete();
    });
    return obs;
  }
  initAuth() {
    this.isAuthenticated().subscribe(
      (auth) => this.authStatusChanged.next(auth)
    );
  }
}
