// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  uiVersion: 'Release_6.6.9', // UI build version
  envName: 'local',
  userPage: true,
  smartpathIconSize: 1, // smartpath grid marker width & height size
  publicMapTruckRecall: 120000, //On public map truck api recall conf in millisecond
  publicMapStreetRecall: 300000, //On public map streets api recall conf in millisecond
  apiUrl: 'http://10.6.4.60:8083/',
  // serverUrl: 'https://x98tupal3l.execute-api.us-east-1.amazonaws.com/dev/', //us-east-1
  serverUrl: 'https://jc8w6fcezg.execute-api.us-east-1.amazonaws.com/prod/',
  // serverUrl: 'https://p8j48kbyn2.execute-api.us-east-2.amazonaws.com/Test/', //us-east-2

  // userPoolId: 'us-east-2_CwUxQ3Mhq',
  // appClientId: '5ebjgdmnlcvj71e3s5nmil39ed',
  
  userPoolId: 'us-east-1_sWTv9JHv7',  //east-1 username without domain name '@'
  appClientId: '33jrpoucffnhsmol6kd44vf21q',

  // userPoolId: 'us-east-1_H1aE2FEVU', //east-1 username with domain name '@'
  // appClientId: 'i1ngj1iips689326ki78bpudq',
  authFlowType: 'USER_PASSWORD_AUTH',
  authType: 'local'  /* either cognito or local */
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
