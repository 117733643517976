import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EditComponent } from 'src/app/shared/components/edit/edit.component';
import { AuthService } from 'src/utils/auth.service';
import { MatSort, Sort } from '@angular/material/sort';
import { colorConfig } from 'src/environments/color-configs';

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss']
})
export class JobsListComponent implements OnInit {

  showTable: boolean = false;
  noRecords: boolean = false;
  jobsArray = [];
  displayCols = ['Name','Description', 'CreatedBy', 'CreatedDate', 'Status'];
  orgId = '';
  searchUrl = '';
  sortUrl = '';
  description = '';
  searchName = '';
  // currentRow = 10;
  totalRecords = 0;
  length = this.totalRecords;
  rowEven = 'row-orange';
  pageSize = 10;
  pageEvent: PageEvent;
  defaultRows: any = this.pageSize;
  defaultPage: any = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100, 200];
  thBg = '#e9ba9e';
  domain = 'snowpaths';
  sensors=[];
  searchBg = 'rgb(221, 92, 15)';
  buttonTextColor = 'black';

  constructor(private apiService: ApiService, private helper: HelperService,
    private authService: AuthService, private dialog: MatDialog) { }

    ngOnInit() {
      this.domain = this.apiService.getLocalStorage('domain');
        this.thBg = colorConfig[this.domain]['thBackground'];
        this.rowEven = colorConfig[this.domain]['reports']['tableRowEvenClass'];
        this.searchBg = colorConfig[this.domain]['reports']['searchBg'];
      this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
        if (session) {
          this.apiService.setAuthResult(session);
          let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
          this.orgId = tokenValue['custom:organizationid'];
        }
      });
      this.sortUrl='sortType=asc&sortBy=Name';
      this.getJobs();
    }
    async getJobs() {
      var url = 'jobs';
      // this.apiService.getSearchwithPagination(url, this.searchUrl, this.sortUrl, this.defaultPage, this.defaultRows).subscribe((data: any) => {
        this.apiService.getByUrl(url).subscribe((data: any) => {
        this.jobsArray = data['jobs'];
        this.totalRecords = this.jobsArray.length;
        this.showTable = true;
        if (this.totalRecords > 0) {
          this.noRecords = false;
        } else {
          this.noRecords = true;
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      })
    }
    handlePage(event: PageEvent) {
      console.log(event);
      this.defaultPage = event.pageIndex;
      this.defaultRows = event.pageSize;
      this.getJobs();
    }
  
    searchChange(event) {
      console.log(event)
      if (event !== '') {
        this.searchUrl = '&name=' + event;
      } else {
        this.searchUrl = '';
      }
      this.defaultPage = 0;
      this.getJobs();
    }
    sortData(sort: Sort) {
      console.log(sort);
      this.searchUrl = '';
      if (sort.active !== 'actions') {
        if (sort.direction) {
          var key = sort.active;
          if (sort.active === "Sensor One") { key = "Sensor1Id" };
          if (sort.active === "Sensor Two") { key = "Sensor2Id" };
          if (sort.active === "Portal") { key = "PortalId" };
          this.sortUrl = "sortBy=" + key + "&sortType=" + sort.direction;
          this.getJobs();
        } else {
          this.sortUrl = '';
          this.getJobs();
        }
      }
    }
    getDescription(id: number, list: any, ddKey: any, ddValue: any) {
      var selectObj = list.find((obj: any) => {
        return obj[ddValue] === Number(id)
      })
      return selectObj && selectObj[ddKey] ? selectObj[ddKey] : '';
    }
}
