import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  pdfSrc: any;
  page = 1;
  constructor(public dialogRef: MatDialogRef<PdfViewerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    // this.pdfSrc=this.src;
    this.pdfSrc = this.data.src;
    console.log(this.pdfSrc);
    console.log(this.data);
  }

}
