import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ApiService } from './api/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend';
  userPoolConfigs: any

  constructor(private apiService: ApiService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document) { }
  ngOnInit() {
    let script = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.id='zsiqchat'
    // script.text = '$zoho.salesiq.ready=function(embedinfo){$zoho.salesiq.floatbutton.visible("hide");}';
    var address = window.location.href;
    if(address.includes('/public/') || address.includes('/Public/') || address.includes('/transport/')){
      script.text = 'var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "204b702faaeffbb1caae2223252f03e6b7a55c843cf6e5fed58bc99b519601f5", values:{},ready:function(){$zoho.salesiq.chatbutton.visible("hide");$zoho.salesiq.floatbutton.visible("hide");}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);'
      console.log("saleIQ disabled")
    }else {
      script.text = 'var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "204b702faaeffbb1caae2223252f03e6b7a55c843cf6e5fed58bc99b519601f5", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);'
    }
    this.renderer2.appendChild(this.document.head, script);
    this.getPoolConfigs
  }
  async getPoolConfigs() {
    var url = 'cognitoUserPool';
    await this.apiService.getByPublicUrl(url).subscribe((data: any) => {
      this.userPoolConfigs = data;
      var userData = JSON.stringify(data)
      this.apiService.addLocalStorage("poolData", btoa(userData))
      // console.log(atob(userData))
      // this.apiService.addLocalStorage("")
    }, (error) => {
      console.log(error);
    })
  }
}
