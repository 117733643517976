import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { colorConfig } from 'src/environments/color-configs';
import { AuthService } from 'src/utils/auth.service';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss']
})
export class MoreInfoComponent implements OnInit {
  domain = 'snowpaths';
  saveBg = 'rgb(241, 119, 46)';
  authResult:any;
  orgId='';
  allPortals: any;
  selectedPortal: any;
  apiType = 'post';
  showPage=false;
  streetPriorityMsg= {
    Message:''
  };

  constructor(private authService: AuthService, private apiService: ApiService,
    private helper: HelperService,private dialog : MatDialog) { }

  ngOnInit() {
    this.domain = this.apiService.getLocalStorage('domain');
      this.saveBg = colorConfig[this.domain]['streetPriority']['saveBackground'];
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = tokenValue['custom:organizationid'];
      }
    });
    this.getPortals();
  }
  //get all portals by orgId
  async getPortals() {
    var url = 'portal?orgId=' + this.orgId;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
        this.selectedPortal = this.allPortals[0]['Id'];
        this.getStreetPriorityMsg()
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  // method to get all street priority messages
  async getStreetPriorityMsg(){
    this.apiType = 'post';
    this.showPage=false;
    this.streetPriorityMsg.Message='';
    var url = 'streetPriorityMsg?orgId=' + this.orgId+'&portalId='+this.selectedPortal;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        if(res['streetPriorityMsgs'][0]){
          this.apiType = 'patch';
          this.streetPriorityMsg = res['streetPriorityMsgs'][0];
        }else {
          this.apiType = 'post';
        }
        console.log(this.apiType)
        this.showPage=true;
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getportalId(portalId) {
    this.showPage = false;
    console.log(portalId);
    this.selectedPortal = portalId;
    this.getStreetPriorityMsg();
  }
  // save new priority or update existing priority
  save(){
    if(this.apiType === 'post'){
      this.createStreetPriorityMsg();
    }else{
      this.updateStreetPriorityMsg();
    }
  }
  // create new priority method
  async createStreetPriorityMsg() {
    var url = 'streetPriorityMsg';
    var payload = {
      "OrganizationId": 1,
      "Message": this.streetPriorityMsg['Message'],
      "PortalId": 1
    }
    await this.apiService.postNewdata(url,payload).subscribe(
      (res: any) => {
        console.log(res)
        this.getStreetPriorityMsg();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  // update street priority message method
  async updateStreetPriorityMsg() {
    var url = 'streetPriorityMsg?id='+this.streetPriorityMsg['Id'];
    var payload = {
      "Message": this.streetPriorityMsg.Message,
    }
    await this.apiService.updatedata(url,payload).subscribe(
      (res: any) => {
        console.log(res)
        this.streetPriorityMsg=res;
        this.getStreetPriorityMsg();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
}
