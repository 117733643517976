import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange, MatDialog } from '@angular/material';
import { ColorPickerControl, ColorsTable } from '@iplab/ngx-color-picker';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ColorEvent } from 'ngx-color';
import { ColorPickerComponent } from 'smart-webcomponents-angular/colorpicker';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { colorConfig } from 'src/environments/color-configs';
import { AuthService } from 'src/utils/auth.service';

@Component({
  selector: 'app-street-priority',
  templateUrl: './street-priority.component.html',
  styleUrls: ['./street-priority.component.scss']
})
export class StreetPriorityComponent implements OnInit {
  @ViewChild('colorpicker', { read: ColorPickerComponent, static: false }) colorpicker!: ColorPickerComponent;
  
  showPage=false;
  authResult:any;
  saveBg = 'rgb(241, 119, 46)';
  labelColor = 'black'
  domain = 'snowpaths';
  streetPriority = [];
  tabs = [];
  allTabs = [];
  selected = new FormControl(0);
  editor = '';
  apiType = 'post';
  newPriority = {
    Title: '',
    Description: '',
    Color: ''
  };
  orgId='';
  allPortals: any;
  streetPriorityMsg= {
    Message:''
  };
  message='';
  selectedPortal: any;
  color = 'red';
  priorityList: any = [];
  existingpriorities: any = [];

  constructor(private authService: AuthService, private apiService: ApiService,
    private helper: HelperService,private dialog : MatDialog) { }

  ngOnInit() {
    this.domain = this.apiService.getLocalStorage('domain');
      this.saveBg = colorConfig[this.domain]['streetPriority']['saveBackground'];
      this.labelColor = colorConfig[this.domain]['reports']['labelColor'];
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = tokenValue['custom:organizationid'];
      }
    });
    this.getPortals();
  }
  //get all portals by orgId
  async getPortals() {
    var url = 'portal?orgId=' + this.orgId;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
        this.selectedPortal = this.allPortals[0]['Id'];
        this.getSelectedPortalData()
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  // get selected portal details method
  async getSelectedPortalData() {
    this.priorityList = [];
    var url = 'portal?orgId=' + this.orgId+'&portalId='+this.selectedPortal;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.priorityList = res['streetPriority'];
        this.getStreetPriorityMsg()
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getportalId(portalId) {
    this.showPage = false;
    console.log(portalId);
    this.selectedPortal = portalId;
    this.getSelectedPortalData();
    // this.getStreetPriorityMsg();
  }
  // method to get all street priority messages
  async getStreetPriorityMsg(){
    this.apiType = 'post';
    this.showPage=false;
    this.streetPriorityMsg.Message='';
    var url = 'streetPriorityMsg?orgId=' + this.orgId+'&portalId='+this.selectedPortal;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        if(res['streetPriorityMsgs'][0]){
          this.apiType = 'patch';
          this.streetPriorityMsg = res['streetPriorityMsgs'][0];
        }else {
          this.apiType = 'post';
        }
        console.log(this.apiType)
        this.getStreetPriority();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  // Method to get all priorities
  async getStreetPriority(){
    var tab = [];
    var existingpriorities = [];
    this.existingpriorities = [];
    // this.streetPriorityMsg.Message='';
    var url = 'streetPriority?orgId=' + this.orgId+'&portalId='+this.selectedPortal+'&msgId='+this.streetPriorityMsg['Id'];
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.streetPriority = res['streetPriority'];
        this.newPriority['Order'] = this.streetPriority.length;
        res['streetPriority'].forEach(function callback(value, index) {
          console.log(value, index);
          existingpriorities.push(value.Title)
          let text = value['Title'];
          tab.push(text);
        });
        tab.push('+')
        this.existingpriorities = existingpriorities;
        console.log('tab items ' + this.tabs)
        this.allTabs = this.tabs.concat(tab);
        this.showPage=true;
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  // save new priority or update existing priority
  save(){
    if(this.apiType === 'post'){
      this.createStreetPriorityMsg();
    }else{
      this.updateStreetPriorityMsg();
    }
  }
  // create new priority method
  async createStreetPriorityMsg() {
    var url = 'streetPriorityMsg';
    var payload = {
      "OrganizationId": 1,
      "Message": this.streetPriorityMsg['Message'],
      "PortalId": 1
    }
    await this.apiService.postNewdata(url,payload).subscribe(
      (res: any) => {
        console.log(res)
        this.getStreetPriorityMsg();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  // save all priorities
  async saveStreetPriority() {
    var url = 'streetPriority?bulk=true';
    this.streetPriority.forEach((priority) => {
      if(!priority.IsDisplayed){
        priority.Color = "#FFFFFF"
      }
      return priority
    })
    await this.apiService.postNewdata(url,this.streetPriority).subscribe(
      (res: any) => {
        console.log(res)
        this.getStreetPriorityMsg();
        this.helper.openSnackBar("Saved successfully", "OK")
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
        this.getStreetPriorityMsg()
      }
    )
  }
  // update street priority message method
  async updateStreetPriorityMsg() {
    var url = 'streetPriorityMsg?id='+this.streetPriorityMsg['Id'];
    var payload = {
      "Message": this.streetPriorityMsg.Message,
    }
    await this.apiService.updatedata(url,payload).subscribe(
      (res: any) => {
        console.log(res)
        this.streetPriorityMsg=res;
        this.getStreetPriorityMsg();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  // existing priority color change event
  onChangeColor(ind,event) {
    const detail = event.detail,
      oldValue = detail.oldValue,
      value = detail.value;
    this.streetPriority[ind]['Color'] = value;
  }
  // // existing priority color change event
  handleChange(ind,$event: ColorEvent) {
    this.streetPriority[ind]['Color'] = $event.color.hex;
  }
  // new priority color change event
  handleNewColorChange($event: ColorEvent){
    this.newPriority['Color'] = $event.color.hex;
  }
  // show/hide color picker with updated color
  showColorPicker(getColor){
    if(getColor){
      return true
    }else {
      return false
    }
  }
  //
  clearNewPriority(){
    this.newPriority = {
      Title:'',
      Description : '',
      Color:''
    }
    this.newPriority['Order']=this.streetPriority.length
  }
  async deleteData(getId) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Are you sure you wish to remove this Status?: \n\n '
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.delStatusRange(getId)
      }
    });
  }
  async delStatusRange(getId){
    var id = 0;
    id = getId - 1;
    var url = 'streetPriority?streetPriorityId='+this.streetPriority[id]['Id'];
    await this.apiService.deleteData(url).subscribe(
      (res: any) => {
        console.log(res)
        this.helper.openSnackBar("Deleted Success", "OK")
        this.getStreetPriorityMsg();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  addNewPriority(){
    if(this.newPriority.Color !== '' && this.newPriority.Title !== '' && this.newPriority.Description !== ''){
    var tabIndex = this.allTabs.indexOf('+');
    this.allTabs[tabIndex]=this.newPriority['Title'];
    this.allTabs.push('+');
    this.newPriority['OrganizationId'] = this.orgId;
    this.newPriority['PortalId'] = this.selectedPortal;
    this.newPriority['StreetPriorityMessageId'] = this.streetPriorityMsg['Id'];
    this.streetPriority.push(this.newPriority);
    this.existingpriorities.push(this.newPriority['Title']);
    this.updatePriorityByOrder(this.streetPriority.length-1,this.newPriority['Order'])
    this.newPriority = {
      Title:'',
      Description:'',
      Color:''
    }
    this.newPriority['Order'] = this.streetPriority.length;
  }else{
    this.helper.openSnackBar("Title, Description & Color fields mandatory","Ok")
  }
  }
  getUpdatedPriorityList(){
    this.apiService.showLoader();
    var updatedpriorities = [];
      this.streetPriority.map((priority: any) => {
        if(priority.IsDisplayed){
          updatedpriorities.push(priority)
        }
      })
      this.apiService.hideLoader();
    return updatedpriorities
  }
  getOrderList(newIndex,getLength){
    var orderList = [];
    for(let ind = 0; ind < getLength; ind++){
      orderList.push(ind);
    }
    return orderList
  }
  updateExistingPriority(value: string, tabIndex: number){
    this.allTabs[tabIndex] = value;
    this.streetPriority[tabIndex-1]['Title'] = value;
    this.existingpriorities = [];
    this.streetPriority.map((priority: any) => {
      this.existingpriorities.push(priority.Title)
    })
  }
  updatePriorityByOrder(from, to){
    this.insertAndShift(this.streetPriority,from,Number(to))
  }
  insertAndShift(arr, from, to) {
    this.apiService.showLoader();
    let cutOut = arr.splice(from, 1) [0]; // cut the element at index 'from'
    arr.splice(to, 0, cutOut);   
    // this.allTabs = [];
    this.streetPriority.map((value, index) =>{
      value.Order=index;
      return value
    });
    this.apiService.hideLoader();
  }
  updateNewOrder(getOrder){
    this.newPriority['Order'] = Number(getOrder)
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.streetPriority, event.previousIndex, event.currentIndex);
    this.streetPriority.map((value, index) =>{
      value.Order=index;
      return value
    });
  }
  viewPriorityChange(rowData,ob: MatCheckboxChange) {
    console.log(ob.checked);
    rowData.IsDisplayed = ob.checked;
    if(rowData.Color === '#FFFFFF' && rowData.IsDisplayed){
      rowData.Color = "#1205F6"
    }
    return rowData
  }
}
