import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordStrengthValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const value: string = control.value;

  const hasLowerCase = /[a-z]/.test(value);
  const hasUpperCase = /[A-Z]/.test(value);
  const hasNumber = /\d/.test(value);
  const hasSpecialChar = /[!@#$%^&*"'|`=()_+{}\[\]:;<>,.?~\\/-]/.test(value);
  const startsWithCharacter = /^[a-zA-Z]/.test(value);

  const isValid =
    hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar && startsWithCharacter;

  return isValid
    ? null
    : {
        passwordStrength: {
          required: true,
          message:
            'Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character and should start with characters.',
        },
      };
};