import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/utils/auth.service';
import { colorConfig } from 'src/environments/color-configs';
import { EditComponent } from 'src/app/shared/components/edit/edit.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email_address = '';
  loginBtnBg = '#f1772e';
  domain = 'snowpaths';
  userPoolConfigs = {}
  password = '';
  confirmPassword = '';
  otp = '';
  emailPattern = "^[a-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$";
  passwordPattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%*?&])[A-Za-zd$@#$!%*?&].{7,15}"
  submitted=false;
  showError=false;
  showOTP = false;
  cognitoUser:any;


  constructor(private apiService: ApiService, private helper: HelperService, private router: Router,
    private authService: AuthService,private dialog:MatDialog) {
      const urlOrigin = window.location.origin;
      let isAvailable = urlOrigin.includes("streetpaths");
      let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
      if (isAvailable) {
        this.domain = 'streetpaths';
      }
      if (isFleetpathAvailable) {
        this.domain = 'fleetpaths';
      }
    this.loginBtnBg = colorConfig[this.domain]['loginBtnBackground'];
  }

  ngOnInit() {
    this.getPoolConfigs();
  }
  async getPoolConfigs() {
    var url = 'cognitoUserPool';
    await this.apiService.getByPublicUrl(url).subscribe((data: any) => {
      this.userPoolConfigs = data;
      var userData = JSON.stringify(data)
      this.apiService.addLocalStorage("poolData", btoa(userData))
    }, (error) => {
      console.log(error);
    })
  }
  onSubmit(form: NgForm) {
    const domain = this.email_address.slice(
      this.email_address.indexOf('@') + 1,
      this.email_address.indexOf(".",this.email_address.indexOf("@")),
    ).toLowerCase();
    console.log(domain)
    if (form.valid) {
    const poolData = {
      UserPoolId: this.userPoolConfigs[domain.toLowerCase()].userPoolId, // Your user pool id here
      ClientId: this.userPoolConfigs[domain.toLowerCase()].clientId // Your client id here
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: this.email_address,
      Pool: userPool,
    };
    this. cognitoUser = new CognitoUser(userData);

    // call forgotPassword on cognitoUser
    this.cognitoUser.forgotPassword({
      onSuccess: (data) => {
        console.log(data)
      },
      onFailure: (err) => {
        console.log('ERR:', err)
        this.helper.openSnackBar(err.message,"Ok")
      },
      inputVerificationCode: (data?:any) => {
        console.log(data)
        this.showOTP=true;
      }
    });
  }
  }
  checkFunction() {
    alert("Hit")
  }
  confirmPasswordSubmit(
    verificationCode: string,
    newPassword: string,form:NgForm
  ): void {
    this.submitted=true;
    if (form.valid) {
    this.cognitoUser.confirmPassword(
      verificationCode,
      newPassword,
      {
        onSuccess: (data) => {
          console.log(data);
          this.confirmChangePwd()
        },
        onFailure: (err) => {
          console.log('ERR:', err)
          this.helper.openSnackBar(err.message,"Ok")
        },
      }
    )
  }else {
    this.showError = true;
  }
  }
  async confirmChangePwd() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Password',
        message: 'You have changed your password successfully. Login with new password : \n\n ' + this.email_address
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
        this.router.navigate(['/']);
    });
  }
}
