import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { ApiService } from 'src/app/api/api.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-widget-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit {

  Highcharts = Highcharts;
  chartOptions = {};
  pieChartData = [{
    name: 'Truck1',
    y: 70.67,
    sliced: true,
    selected: true
}, {
    name: 'Truck2',
    y: 14.77
},  {
    name: 'Truck3',
    y: 4.86
}, {
    name: 'Truck4',
    y: 2.63
}, {
    name: 'Truck5',
    y: 1.53
},  {
    name: 'Truck6',
    y: 1.40
}, {
    name: 'Truck7',
    y: 0.84
}, {
    name: 'Truck8',
    y: 0.51
}, {
    name: 'Truck9',
    y: 2.6
}]

  @Input() data = [];
  @Input() empty = '';
  @Input() isLite: boolean
  showChart=false

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    const buttonOptions = {
      text: 'CSV',
      onclick: () => {
        this.downloadCSV();
      },
      title: 'Download CSV file'
    };
    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        // height: 300,
        // width: 300
      },
      title: {
        text: 'Vehicles by Vehicle type',
        style: {
          // color: 'red', // title color
          fontSize: '12px',
          fontWeight: 'bold',
          'font-family': '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif'
        }
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          showInLegend: true
        }
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen','exitFullscreen','printChart','separator','downloadPNG','downloadJPEG', 'downloadPDF', 'downloadSVG', 'downloadCSV'],
          },
          downloadCSV: buttonOptions
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Vehicle Type',
        colorByPoint: true,
        // this.data
        data: this.data
      }]
    };
    if(this.data && this.data.length > 0){
      this.showChart = true;
    }

    HC_exporting(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }
  downloadCSV(){
    var url = 'downloadReportCsv?truckByTruckType=true';
    this.apiService.postNewdata(url,this.data).subscribe((data: any) => {
      var csvData = data['csvData'];
      this.downloadCsvFile(csvData,'Vehicles-by-Vehicletype.csv')
    }, (error) => {
      console.log(error);
    })
  }
  downloadCsvFile(base64Data: string, fileName: string) {
    const decodedData = atob(base64Data);
    const blob = new Blob([decodedData], { type: 'text/csv' });
    saveAs(blob, fileName);
  }

}
