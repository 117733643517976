import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmationDialogService } from './confirmation-dialog.service';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard
  implements CanDeactivate<CanComponentDeactivate>
{
  zoneCreation: any = false;
  constructor(private confirmationDialogService: ConfirmationDialogService) {
  }

  canDeactivate(
    component: CanComponentDeactivate
  ): boolean | Observable<boolean> {
    return (component['polygon'] || ( component['gridObj'] && component['gridObj']['Name']))
      ?this.confirmationDialogService.openConfirmationDialog(): true;
  }
}