import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { HelperService } from 'src/app/api/helper.service';
import { AuthService } from 'src/utils/auth.service';
import { colorConfig } from 'src/environments/color-configs';

@Component({
  selector: 'app-load-street',
  templateUrl: './load-street.component.html',
  styleUrls: ['./load-street.component.scss']
})
export class LoadStreetComponent implements OnInit {
  saveBg = 'rgb(241, 119, 46)';
  domain = 'snowpaths';
  authResult:any;
  orgId='';
  loadStreetObj: any = {
    placeName: "",
    orgId: null,
    portalId: null
  };

  constructor(private helper: HelperService, private apiservice: ApiService,
    private authService : AuthService,) { }

  ngOnInit() {
    this.domain = this.apiservice.getLocalStorage('domain');
      this.saveBg = colorConfig[this.domain]['logo']['saveBackground'];
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiservice.setAuthResult(session);
        let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = tokenValue['custom:organizationid'];
      }
    });
  }
  async save() {
    console.log(this.loadStreetObj)
    var placeLength = this.loadStreetObj.placeName.split(',');
    if(placeLength.length === 2 && placeLength[0] && placeLength[1] && this.loadStreetObj.orgId && this.loadStreetObj.portalId){
      var url = 'loadStreets';
      await this.apiservice.postNewdata(url,this.loadStreetObj).subscribe(
        (res: any) => {
          console.log(res);
          this.helper.openSnackBar("Streets upload successfully initiated!", "OK")
        }, (error) => {
          console.log(error);
          this.helper.openSnackBar(error, "OK")
        }
      )
    }else {
      this.helper.openQuickSnackBar('All fields are mandatory & Place format should be "City, State"', "OK")
    }

  }

}
