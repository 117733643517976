import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { ApiService } from 'src/app/api/api.service';
import { saveAs } from 'file-saver';
import { colorConfig } from 'src/environments/color-configs';

@Component({
  selector: 'app-widget-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit {
  @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;
  Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  showChart=false;
  updateFlag = false;
  defaultUnit = 'pound'
  newUnit = '';
  labelColor='black';
  domain='snowpaths';
  // data(s) from parent components
  @Input() data = [];
  @Input() title = '';
  @Input() empty = '';
  @Input() unit = '';
  @Input() yAxisName=''
  @Input() showToggle: boolean
  @Input() isLite: boolean
  yname = '';

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.domain = this.apiService.getLocalStorage('domain');
    this.labelColor=colorConfig[this.domain]['reportChart']['labelColor'];
    this.yname = this.yAxisName;
    // csv button adding on chartOptions
    const buttonOptions = {
      text: 'CSV',
      onclick: () => {
        this.downloadCsv();
      },
      title: 'Download CSV file'
    };
    // salt toggle button adding on chartOptions
    const toggleButtonOptions = {
      // text: '',
      onclick: () => {
        this.showSaltsInPoundKg();
      },
      symbol: 'url(https://w7.pngwing.com/pngs/365/764/png-transparent-computer-icons-refresh-free-one-button-reload-text-logo-monochrome-thumbnail.png)',
      symbolX: 20,
      symbolY: 20
      // title: 'View in Pound/Kg'
    };
    // bar chart data options
    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column',
        backgroundColor: 'white',
        // height: 300,
        // width: 300
      },
      title: {
        text: this.title,
        style: {
          // color: 'red', // title color
          fontSize: '12px',
          fontWeight: 'bold',
          'font-family': '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif'
        }
      },
      series: this.data['yAxisData'],
      tooltip: {
        // headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        //     '<td style="padding:0"><b>{point.y:.1f} '+this.unit+'</b></td></tr>',
        // footerFormat: '</table>',
        valueSuffix: ' '+this.unit,
      //   shared: true,
      //   useHTML: true,
      //   split: true,
      //   formatter: function() {
      //     if (this.series.type === 'bar' && this.point.selected) {
      //       return `<b>${this.x}</b>: ${this.y}`;
      //     }
      //     return false;
      //     const selectedBar = this;
      //     // Get Y-axis name from series
      //     const header = selectedBar.x;
      //     const yAxisName = selectedBar.y;
      //     const xAxisObj = selectedBar.points.filter(bar => {
      //       return bar.y === yAxisName
      //     })
          
      //     // Return tooltip content with Y-axis name
      //     return header+'<br>'+ xAxisObj[0].series.name + ' : ' + yAxisName;
      // }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
      }
      },
      xAxis: {
        categories: this.data['xAxis'],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: this.yAxisName
        }
    }
      
    };
    // add chart buttons based on conditions
    if(this.showToggle){
      this.chartOptions.yAxis['title'].text = this.yname+' in '+this.unit;
    this.chartOptions['exporting'] = {
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen','exitFullscreen','printChart','separator','downloadPNG','downloadJPEG', 'downloadPDF', 'downloadSVG', 'downloadCSV','toggleBtn'],
        },
        downloadCSV: buttonOptions,
        toggleBtn: toggleButtonOptions
      }
    }
  }else {
    this.chartOptions['exporting'] = {
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen','exitFullscreen','printChart','separator','downloadPNG','downloadJPEG', 'downloadPDF', 'downloadSVG', 'downloadCSV'],
        },
        downloadCSV: buttonOptions
      }
    }
  }
    if(this.data && this.data['yAxisData'] && this.data['yAxisData'].length > 0){
      this.showChart = true;
    }

    HC_exporting(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

  // Download CSV file from chart data
  public downloadCsv(){
    var url = 'downloadReportCsv?truckByTruckType=false';
    this.apiService.postNewdata(url,this.data).subscribe((data: any) => {
      var csvData = data['csvData'];
      this.downloadCsvFile(csvData,this.title+'.csv')
    }, (error) => {
      console.log(error);
    })
  }
  // On salt chart toggle data view between kg/tons functionality
  showSaltsInPoundKg(){
    this.showChart = false;
    this.defaultUnit = this.defaultUnit === 'pound'?'kilo':'pound';
    this.data['yAxisData'][0]['data']=Object.assign([],this.data['yAxisData'][0][this.defaultUnit])
    this.chartOptions['series']= Object.assign([],this.data['yAxisData'])
    this.unit = this.defaultUnit === 'pound'?'Ton(s)':'Kg';
    this.chartOptions.yAxis['title'].text = this.yname+' in '+this.unit;
    this.chartOptions['tooltip'] = Object.assign({},{
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} '+this.unit+'</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    })
    this.showChart = true;
    this.updateFlag = true;
  }
  // download csv file
  downloadCsvFile(base64Data: string, fileName: string) {
    const decodedData = atob(base64Data);
    const blob = new Blob([decodedData], { type: 'text/csv' });
    saveAs(blob, fileName);
  }
  
}