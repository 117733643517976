import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss']
})
export class InstructionComponent implements OnInit {
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  instructionType="";
  role=''
  userManualData: any;
  manualList=[];
  selectedItem='';
  itemSelected=false;
  pdfSelected=false;
  manualVideos = [];
  showView = false;

  constructor(private route: ActivatedRoute, private apiService: ApiService,
    private sanitized: DomSanitizer,
    private helper: HelperService) { 
      this.role=apiService.getLocalStorage('role');
    }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        console.log('Got param: ', params['instructionType']);
        this.instructionType =  JSON.parse(params['instructionType']);
        console.log(this.instructionType);
      }
    )
    this.getInstructionsManuals();
  }

  async getInstructionsManuals() {
    var url = 'userManualS3Url';
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res);
        this.userManualData = res;
        if(this.role === 'Admin' && this.instructionType === "manuals"){
          this.manualList=this.userManualData['Admin']['UserManual'];
          // this.manualList=this.userManualData['Admin']['UserManual'].concat(this.userManualData['User']['UserManual'])
        }else {
          this.manualList=this.userManualData['User']['UserManual'];
        }
        if(this.role === 'Admin' && this.instructionType === "videos"){
          this.manualVideos=this.userManualData['Admin']['InstructionalVideos'].concat(this.userManualData['User']['InstructionalVideos'])
        }else {
          this.manualVideos=this.userManualData['User']['InstructionalVideos'];
        }
        console.log(this.manualList)
        console.log(this.manualVideos)
        this.showView=true;
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader()
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  selectItem(getItem, type){
    this.selectedItem=getItem;
    if(type === "videos"){
      this.itemSelected=true;
    }else {
      this.pdfSelected = true;
    }
  }
  getName(getItem){
    return getItem.split('/').pop()
  }
  goBack(){
    this.pdfSelected=false;
    this.itemSelected=false;
  }

}
