import { Component, OnInit, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { colorConfig } from 'src/environments/color-configs';
import { AuthService } from 'src/utils/auth.service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Router } from '@angular/router';
import { MatDialog, MatRadioChange } from '@angular/material';
import { HelperService } from 'src/app/api/helper.service';
import { ConfirmOkDialogComponent } from 'src/app/shared/components/confirm-ok-dialog/confirm-ok-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-street-highway-change',
  templateUrl: './street-highway-change.component.html',
  styleUrls: ['./street-highway-change.component.scss']
})
export class StreetHighwayChangeComponent implements OnInit {
  isLoading = true;
  message = "Loading, Please wait a while..."
  prioritiesData: any;
  priorityGeojsonData: any;
  OriginalpriorityGeojsonData: any;
  highwayTypes: any = [];
  bgColor = 'rgb(253, 235, 224)';
  mapClass = 'map-container-70';
  endClass = 'end-container-24';
  showMap:boolean =false;
  showStreet: boolean = false;
  domain = 'snowpaths';
  urlOrigin: string = '';

  lat = 41.101721;
  lng = -80.643667;
  lastSelectedLat = 0;
  lastSelectedLng = 0;
  zoom = 0;
  mapType = 'roadmap'; //roadmap or satellite
  public customStyle = [{  
    "featureType": "poi",  
    "stylers": [{  
        visibility: "off",  
    }]  
  }, ];
  authResult: any;
  orgId: any = 0;
  userEmail: string = '';
  boundaryUrl: any;
  appVersion: number = 0;
  pilotMode: number = 0;
  GIS: number = 1;
  selectedPortal: any = 0;
  upodatedHighwayData = [];
  searchBg = 'rgb(221, 92, 15)';
  labelColor = 'black';
  selectedHighway: any = '';
  allPortals: any;
  id: any;

  constructor(private apiService: ApiService, private authService: AuthService, private cdr: ChangeDetectorRef,
    private router: Router, private helper: HelperService, private dialog: MatDialog) {
    const urlOrigin = window.location.origin;
    this.urlOrigin = window.location.origin;
    let isAvailable = urlOrigin.includes("streetpaths");
    let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    if(isFleetpathAvailable){
      this.domain='fleetpaths';
    }
    if(isAvailable){
      this.domain='streetpaths';
    }
    this.handlePortalStyles();
   }

  ngOnInit() {
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = tokenValue['custom:organizationid'];
        this.userEmail = tokenValue['email']
        // this.getUserDetails();
        
        this.getOrgDetails();
      } else {
        this.router.navigate[('/')]
      }
    });
    if (!this.authResult) {
      localStorage.clear();
      this.router.navigate[('/')]
    }
  }
  async getPortals() {
    var url = 'portal?orgId=' + this.orgId;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
        var privateReportData = JSON.parse(this.apiService.getLocalStorage('reportData'))
        if(privateReportData){
          this.selectedPortal = privateReportData.PortalId;
        }else {
          this.selectedPortal = this.allPortals[0]['Id'];
        }
        this.getPriorityData();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  handlePortalStyles(){
    this.domain=this.apiService.getLocalStorage('domain');
    this.bgColor = colorConfig[this.domain]['reports']['background'];
    this.searchBg = colorConfig[this.domain]['reports']['searchBg'];
    this.labelColor = colorConfig[this.domain]['reports']['labelColor'];
  }
  async getOrgDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.boundaryUrl = data.boundaryUrl;
      this.appVersion = data.organization['Lite'];
      this.pilotMode = data.organization['Pilot'];
      this.GIS = data.organization['GIS'];
      this.lat = data['siteSetting']['CenterLatitude'];
      this.lng = data['siteSetting']['CenterLongitude'];
      this.zoom = data['siteSetting']['zoomLevel'];
      var preZoom = this.apiService.getLocalStorage('mapZoom');
      if(preZoom){
        this.zoom=Number(preZoom)
      }
      // this.showMap = true;
      this.getPortals();
      // this.getPriorityData();
      
      
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getPriorityData() {
    this.showStreet = false;
    this.highwayTypes = [];
    this.prioritiesData = {};
    var url = 'changeStreetHighway?orgId='+this.orgId+'&portalId='+this.selectedPortal;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.prioritiesData = data;
      var index = 0;
      for(let key in this.prioritiesData.highways){
        if(index < 4){
          this.highwayTypes.push(key)
          index = index + 1;
        }
      }
      this.isLoading = false;
      if(this.prioritiesData.streetPriorityS3Url !== ''){
        this.message = "Streets loading... ";
        this.getgeojsonData();
        // this.showMap = true;
      } else {
        this.message = "Priority street not loaded for this portal "+this.selectedPortal;
        this.showMap = false;
        this.showStreet = false;
      }
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  getgeojsonData(){
    this.apiService.getByExternalUrl(this.prioritiesData.streetPriorityS3Url).subscribe((data: any) => {
      this.priorityGeojsonData = data;
      this.OriginalpriorityGeojsonData = data;
      this.showMap = true;
      this.showStreet = true;
      console.log(data);
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  zoomChange(event: any){
    this.zoom=event;
    console.log(this.zoom)
    // this.apiService.addLocalStorage('mapZoom',this.zoom)
  }
  onMapClick(event: MouseEvent) {
    console.log(event)
  }
  lineStringStyle(feature: any) {
    // #b99885 brown
    const strokeColor = feature.getProperty('stroke');
    const strokeWeight = feature.getProperty('strokeWeight');
    const strokeOpacity = feature.getProperty('strokeOpacity');
    return {
      strokeColor: strokeColor?strokeColor:'#b99885',
      strokeWeight: strokeWeight?strokeWeight:4,
      strokeOpacity: strokeOpacity?strokeOpacity:0.4
    };
  }
  dalaLayerClicked(event: any){
    console.log(event)
    if(this.selectedHighway === ''){
      this.showAlert('Please select any one priority before update street priority: ');
    } else {
    const featureId = event.feature.getId();
    // const featureHighway = event.feature.getProperty('highway');
    const featureStreetName = event.feature.getProperty('name');
    var priorityObj = {
      uvKeyId : featureId,
      highway: this.selectedHighway,
      name: featureStreetName
    }
    this.upodatedHighwayData.push(priorityObj);
    // this.changeStreetColor(featureId);
    this.lastSelectedLat = event['latLng'].lat();
      this.lastSelectedLng = event['latLng'].lng()
    console.log(JSON.stringify(this.upodatedHighwayData))
  }
  }
  reverseStringInParentheses(inputString: string): string {
    return inputString.replace(/\(([^,]+), ([^,]+), ([^)]+)\)/, '($2, $1, $3)');
  }
  changeStreetColor(){
    this.showStreet = false;
    if(this.upodatedHighwayData.length > 0){
    for(let feature of this.upodatedHighwayData){
    //Find index of specific object using findIndex method.   
    // this.priorityGeojsonData = {};
    var vuKeyId = this.reverseStringInParentheses(feature.uvKeyId);
    this.priorityGeojsonData = {};
    this.priorityGeojsonData = Object.assign({},this.OriginalpriorityGeojsonData);
    var objIndex = this.OriginalpriorityGeojsonData.features.findIndex((obj => obj.id === feature.uvKeyId));
    var revobjIndex = this.OriginalpriorityGeojsonData.features.findIndex((obj => obj.id === vuKeyId));

    //Log object to Console.
    // console.log("Before update: ", this.priorityGeojsonData[objIndex])
    //Update object's name property.
    if(objIndex){
      this.priorityGeojsonData.features[objIndex]['properties']['highway'] = feature.highway;
      this.priorityGeojsonData.features[objIndex]['properties']['stroke'] = this.prioritiesData.highways[feature.highway];
    }
    if(revobjIndex){
      this.priorityGeojsonData.features[revobjIndex]['properties']['highway'] = feature.highway;
      this.priorityGeojsonData.features[revobjIndex]['properties']['stroke'] = this.prioritiesData.highways[feature.highway];
    }

  }
  setTimeout(() => 
  {
    this.showStreet = true;
  }, 100); // 6000 is 1 second
} else {
  this.showMap = false;
  this.getgeojsonData();
}
  this.lat = this.lastSelectedLat;
  this.lng = this.lastSelectedLng;
  }
  highwayChange(event: MatRadioChange) {
    console.log(event);
    console.log(event.value);
    this.selectedHighway = event.value;
    console.log(event.source);
    //this.filter['property'] = this.selected;
    //console.log(this.filter);
  }
  deleteItem(feature: any){
    this.upodatedHighwayData.forEach((element,index)=>{
      if(element.uvKeyId==feature.uvKeyId) this.upodatedHighwayData.splice(index,1);
   });
   if(this.upodatedHighwayData.length === 0){
    this.changeStreetColor();
   }
  }
  savePriority(){
    var url = 'changeStreetHighway?orgId='+this.orgId+'&portalId='+this.selectedPortal;
    var payload = {
      "orgId":Number(this.orgId),
      "portalId":this.selectedPortal,
      "updates":this.upodatedHighwayData
      }
    this.apiService.postNewdata(url, payload).subscribe((data: any) => {
      console.log(data);
      this.helper.openSnackBar("Priority changed successfully.", "OK")
      this.changeStreetColor();
      this.upodatedHighwayData = [];
      // this.highwayTypes = [];
      this.selectedHighway = '';
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async resetAllPriorityColors(zone) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Reset',
        message: 'Please confirm that you\'d like to reset all priority colors to default color:?: \n\n '
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.confirmReset()
      }
    });
  }
  confirmReset(){
    var url = 'streetPriority?updateDefaultColor=true&orgId='+this.orgId+'&portalId='+this.selectedPortal;
    var payload = {
      "orgId":Number(this.orgId),
      "portalId":this.selectedPortal,
      "updates":this.upodatedHighwayData
      }
    this.apiService.updatedata(url,{}).subscribe((data: any) => {
      console.log(data);
      this.helper.openSnackBar("Priority colors changed to default successfully.", "OK")
      this.upodatedHighwayData = [];
      this.highwayTypes = [];
      this.selectedHighway = '';
      this.getPriorityData();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async showAlert(message: any) {
    const confirmDialog = this.dialog.open(ConfirmOkDialogComponent, {
      data: {
        title: 'Alert',
        message: message
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      
    });
  }
  getportalId(portalId) {
    
    console.log(portalId);
    this.selectedPortal = Number(portalId);
    this.getPriorityData();
  }
}
