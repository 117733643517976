import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

import { ConfirmationService } from 'primeng-lts/api';
import { FormsModule  } from '@angular/forms';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() data: any;
  @Input() facetData: any;
  @Output() onFilterChanged: EventEmitter<any> = new EventEmitter<any>();
  objMetaData = [];
  facetList = {};
  filter = {};
  queryUrlProperties = {};
  queryUrl = '';
  tempUrl = '';
  tempInputType = '';
  count = 0;
  arrayLength = 0;
  dateOption = [
    {
      nameId: 'startDate',
      label: 'Start Date'
    },
    {
      nameId: 'endDate',
      label: 'End Date'
    }
  ];
  facetUIData = {
    metaData: []
  };
  filterByFacet = false;
  constructor(private httpservice: ApiService) {}

  ngOnInit() {
    if (this.filterByFacet) {
      this.renderByMetaDataAndFacet(this.data, this.facetList);
    } else {
      this.renderByMetaData(this.data);
    }
    this.objMetaData = this.data;
    this.facetList = this.facetData;
    this.inputDateRange(this.data);

  }
  getInputType(returnType, getNameId) {
    for ( const c of this.objMetaData ) {
      if (c.nameId === getNameId) {
        return c[returnType];
      }
    }
  }
  renderByMetaData(metaData) {
    for (const meta of metaData) {
      if (meta.inputType === 'date') {
        this.filter[meta.nameId] = {
          startDate : '',
          endDate : ''
        };
      }
    }
  }
  renderByMetaDataAndFacet(getMeta, getFacetData) {

    for ( const c of getMeta) {
      if (c.facetOrder !== undefined) {
        if (c.datatype !== 'enum') {
          this.facetUIData.metaData.push(c);
        } else {
          const tempMetaData = Object.assign({}, c);
          tempMetaData.options = [];
          for (const facet in getFacetData.facets) {
            const tempFacetVal = getFacetData.facets[facet];
            if (facet === c.nameId && tempFacetVal.facetValues.length > 0) {
              const tempfacetList = [];
              for (const facetValue of tempFacetVal) {
                for (const option of c.option) {
                  if (facetValue.name === option.nameId) {
                    let tempObj = {};
                    tempObj = {
                      value : option.value,
                      label : option.label + '(' + facetValue.count + ')'
                    };
                    tempfacetList.push(tempObj);
                  }
                }
              }
              this.facetUIData.metaData.push(tempfacetList);
            }
          }
        }
      }
    }
  }
  inputDateRange(getMetaData) {
    for ( const c of getMetaData) {
      if (c.inputType === 'date') {
        this.filter[c.nameId] = {};
      }
    }
  }
  onChangeInput(nameId, value) {
    for ( const c of this.objMetaData ) {
        this.queryUrlProperties[nameId] = [];
        this.queryUrlProperties[nameId] = value;
    }
    this.writeSearchURL();
  }

  onChangeTextInput(nameId, value, event) {
    if (event.key === 'Enter') {
    for ( const c of this.objMetaData ) {
      this.queryUrlProperties[nameId] = '';
      this.queryUrlProperties[nameId] = value;
    }
    this.writeSearchURL();
  }
  }

  writeSearchURL() {
    this.queryUrl = '';
    for (const query in this.queryUrlProperties) {
      this.tempInputType = this.getInputType('inputType', query);
      const type = this.getInputType('inputType', query);
      if (this.tempInputType !== 'dropDown') {
        if (type !== 'date') {
          if (this.queryUrlProperties[query] !== '') {
          this.queryUrl += ' AND (' + query + ':\'' + this.queryUrlProperties[query] + '\')';
          }
        } else {
          this.queryUrl += 'AND ((' + query + ':\'' + this.queryUrlProperties[query].startDate + '\'TO\'' + this.queryUrlProperties[query].endDate + '\')';
        }
      } else {
          if (this.queryUrlProperties[query].length > 0) {
            this.tempUrl = '';
            this.queryUrl += ' AND ';
            this.arrayLength = this.queryUrlProperties[query].length;
            this.count = 0;
            for ( const value of this.queryUrlProperties[query]) {
            this.count++;
            this.tempUrl += '(=' + query + ':\'' + value + '\')';
            if (this.count < this.arrayLength) {
              this.tempUrl += ' OR ';
            }
          }
            this.queryUrl += this.tempUrl;
        }
      }
    }
    this.filterChanged(this.queryUrl.slice(5));
  }

  onChangeDateInput(nameId, startDate, endDate) {
    let dateOptions = {};
    if ((startDate !== undefined && endDate !== undefined) && (startDate !== '' && endDate !== '') && (startDate !== null && endDate !== null)) {
      // var sDate = new Date(startDate).toISOString();
      // var eDate = new Date(endDate).toISOString();
      dateOptions = {
        startDate,
        endDate
        // 'startDate' : this.datepipe.transform(startDate, 'dd/MM/yyyy'),
        // 'endDate' : this.datepipe.transform(endDate, 'dd/MM/yyyy')
      };
      this.queryUrlProperties[nameId] = dateOptions;
    } else {
      if (this.queryUrlProperties[nameId] !== undefined) {
        delete this.queryUrlProperties[nameId];
      }
    }

    this.writeSearchURL();

    // var keys = Object.keys(this.dateOptions[nameId]);
    // var len = keys.length;
    // alert(len)
  }

  public filterChanged(data: any): void {
    this.onFilterChanged.emit(data);
}
eventHandler(event) {
  console.log(event, event.keyCode, event.keyIdentifier);
}

clearAll() {
  this.queryUrlProperties = [];
  this.filter = {};
  this.renderByMetaData(this.objMetaData);
  this.writeSearchURL();
}
}
