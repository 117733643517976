import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';

@Component({
  selector: 'app-widget-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() label: string;
  @Input() total: string;
  @Input() percentage: string;
  @Input() data: string;

  Highcharts = Highcharts;
  chartOptions = {};


  constructor() { }

  ngOnInit() {
    var chartWidth = window.innerWidth <450?window.innerWidth:window.innerWidth/4;
    this.chartOptions = {
      chart: {
        type: 'area',
          backgroundColor: 'white',
        //   borderWidth: 0,
        //   margin: [2, 2, 2, 2],
        // height: 300,
        // width: 300
      },
      title: {
        text: 'Time by zone'
      },
      subtitle: {
        style: {
          position: 'absolute',
          right: '0px',
          bottom: '10px'
        }
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: -150,
        y: 100,
        floating: true,
        borderWidth: 1,
        backgroundColor: '#FFFFFF'
      },
      xAxis: {
        //     labels: {
        //       enabled: false,
        //     },
        //     title: {
        //       text: null
        //     },
        //     startOnTick: false,
        //     endOnTick: false,
        //     tickOptions: []
        categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      },
      yAxis: {
        title: {
          text: 'Number of units'
        },
        labels: {
          formatter: function () {
            return this.value;
          }
        },
        min: 0
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.series.name + '</b><br/>' +
            this.x + ': ' + this.y;
        }
      },
      plotOptions: {
        area: {
          fillOpacity: 0.5
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'Vehicle 1',
          data: [3, 4, 3, 5, 4, 10, 12]
        },
        {
          name: 'Vehicle 2',
          data: [1, 3, 4, 3, 3, 5, 4]
        },
        {
          name: 'Vehicle 3',
          data: [2, 2, 5, 1, 2, 6, 2]
        },
        {
          name: 'Vehicle 4',
          data: [4, 1, 2, 4, 5, 7, 8]
        }
      ]
    };

    HC_exporting(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

}
