import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, forwardRef } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { DefaultModule } from './layouts/default/default.module';
import { AngularMaterialModule } from './angular-material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WINDOW_PROVIDERS } from './window.provider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CalendarModule } from 'primeng-lts/calendar';
import { MessagesModule } from 'primeng-lts/messages';
import { MessageModule } from 'primeng-lts/message';
import { DialogModule } from 'primeng-lts/dialog';
import { ConfirmDialogModule } from 'primeng-lts/confirmdialog';
import { ConfirmationService } from 'primeng-lts/api';
import { PaginatorModule } from 'primeng-lts/paginator';
import { TableModule } from 'primeng-lts/table';
import { MultiSelectModule } from 'primeng-lts/multiselect';
import { DropdownModule } from 'primeng-lts/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { TooltipModule } from 'primeng-lts/tooltip';
import { SidebarModule } from 'primeng-lts/sidebar';
import {SplitButtonModule} from 'primeng-lts/splitbutton';
import { DatePipe } from '@angular/common';
import {MessageService} from 'primeng-lts/api';
import {OverlayPanelModule} from 'primeng-lts/overlaypanel';
import {EditorModule} from 'primeng-lts/editor';
import { ToastModule } from 'primeng-lts/toast';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ProgressBarModule} from 'primeng-lts/progressbar';
import { AuthService } from 'src/utils/auth.service';
import { AgmCoreModule, AgmMarker, FitBoundsAccessor } from '@agm/core';
import { AgmDrawingModule, AgmDrawingManager } from '@agm/drawing';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { ColorPickerModule } from 'smart-webcomponents-angular/colorpicker';
import { ColorSketchModule } from 'ngx-color/sketch';
// import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { ImageDragDirective } from './shared/directives/image-drag.directive';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { QRCodeModule } from 'angularx-qrcode';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PdfViewerModule, RenderTextMode } from 'ng2-pdf-viewer';
import { CookieService } from 'ngx-cookie-service';
import { ErrorInterceptor } from './api/error-interceptor.service';


@NgModule({
  declarations: [
    AppComponent,ImageDragDirective
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    DefaultModule,
    AngularMaterialModule,
    MatFormFieldModule,
    MatInputModule,
    PdfViewerModule,
    MatButtonModule,
    CalendarModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    InputSwitchModule,
    ConfirmDialogModule,
    PaginatorModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    TooltipModule,
    ProgressBarModule,
    SidebarModule,
    SplitButtonModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAYEw3YiYG_952mOPRFpe-H4ZJaXsEV2ng',
      libraries: ['places', 'drawing', 'geometry']
    }),
    AgmDrawingModule,
    AgmMarkerClustererModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    OverlayPanelModule,
    ColorPickerModule,
    ColorSketchModule,
    EditorModule,
    ToastModule,
    QRCodeModule,
    NgMultiSelectDropDownModule
    // NgxEditorModule
  ],
  providers: [CookieService, AuthService,AgmDrawingManager, DatePipe,
    {provide: FitBoundsAccessor, useExisting: forwardRef(() => AgmMarker)},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    }],

  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
