import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NominatimService {

  private apiUrl = 'https://nominatim.openstreetmap.org/reverse';
        //  var url = 'https://nominatim.openstreetmap.org/reverse'

  constructor(private http: HttpClient) { }

  getAddress(lat: number, lng: number): Observable<any> {
    var url = this.apiUrl+'?lat=' + lat + '&lon=' + lng + '&format=json'
    return this.http.get(url);
  }
}
