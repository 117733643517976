import { ClusterManager } from '@agm/markerclusterer';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { colorConfig } from 'src/environments/color-configs';
import { AuthService } from 'src/utils/auth.service';

@Component({
  selector: 'app-route-map',
  templateUrl: './route-map.component.html',
  styleUrls: ['./route-map.component.scss']
})
export class RouteMapComponent implements OnInit {
  lat = 40.9319951;
  lng = -101.1079758;
  zoom = 5;
  iconPath = {
    url: '../../../assets/logos/bus.png',
    scaledSize: {
      width: 30,
      height: 30
    }
  }
  mapType = 'roadmap'; //roadmap or satellite
  public customStyle = [{  
    "featureType": "poi",  
    "stylers": [{  
        visibility: "off",  
    }]  
  }, ];
  showMap = false;
  OrgDetails: any = [];
  boundaryUrl: string = '';
  appVersion: any;
  pilotMode: number;
  orgName: string = '';
  GIS: any = 1;
  orgId: any = '';
  authResult: any;
  transportRoutesList=[];
  selectedRoute: any = {}
  showNewRoute: boolean = false;
  showMapTruck: boolean = false;
  routeGjson: any;
  showRoute: boolean = false;
  busStopLists = [];
  searchBg = 'rgb(221, 92, 15)';
  domain = 'snowpaths';
  showUpload: boolean = false;
  newRoute = {
    color: '#0F9AF0'
  };
  allPortals: any;
  selectedPortal: any
  allTransportRoutes: any = [];
  trucksTypesArray: any = [];
  trucksArray: any = [];
  selectedTruckType: any = '';
  selectedTruck: any = [];
  dropdownSettings: IDropdownSettings = {};
  busReport: any = {};
  selectedBusStopDetails: any = {};

  constructor(private apiService: ApiService, private authService: AuthService,
    private router: Router, private http: HttpClient, private helper: HelperService) { }

  ngOnInit() {
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = tokenValue['custom:organizationid'];
        // this.getUserDetails();
        this.getOrganizations();
      } else {
        this.router.navigate[('/')]
      }
    });
    if (!this.authResult) {
      localStorage.clear();
      this.router.navigate[('/')]
    }
    this.domain = this.apiService.getLocalStorage('domain')
    this.searchBg = colorConfig[this.domain]['reports']['searchBg'];
  }
  zoomChange(event: any){
    this.zoom=event;
    console.log(this.zoom)
  }
  async getOrganizations() {
    var url = 'organization?orgId=' + this.orgId;
    await this.apiService.getByUrl(url).subscribe(
      (data: any) => {
        this.OrgDetails = data;
        this.boundaryUrl = data.boundaryUrl;
        this.appVersion = data.organization['Lite'];
        this.pilotMode = data.organization['Pilot'];
        this.orgName = data.organization['Name'];
        this.GIS = data.organization['GIS'];
        this.lat = data['siteSetting']['CenterLatitude'];
      this.lng = data['siteSetting']['CenterLongitude'];
      this.zoom = data['siteSetting']['zoomLevel'];
      this.getPortals();
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader()
      }
    )
  }
  
  getClusterDetails(cluster: ClusterManager): void {
    // cluster.markers_.map(m => { console.log(m); }); 
    console.log(cluster)
  }
  async selectRoute(getRoute: any){
    this.routeGjson = {};
    this.busStopLists=[];
    console.log(getRoute);
    this.selectedRoute = getRoute;
    var url = 'transport?orgId='+this.orgId+'&portalId='+this.newRoute['portalId']+'&transportRouteId='+getRoute.Id+'&trackRouteBus=true'
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.busReport = res;
        this.busStopLists = res.stops;
        if(res.transportRouteS3Url !== ''){
          this.http.get(res.transportRouteS3Url).subscribe(data => {
            console.log(data)
            this.splitStreetsFromGeojson(data, getRoute);
            // this.getStopsData(data, getRoute);
          })
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  splitStreetsFromGeojson(data, getRoute){
    this.showRoute = false;
    var routeGjson = {
      type: "FeatureCollection",
      features: []
    }
    if(data['features'] && data['features'].length >0){
      
      data['features'].map((feature: any)=>{
        if(feature.geometry.type === "LineString"){
          routeGjson.features.push(feature)
        }
      })
      if(routeGjson.features.length >0){
        this.routeGjson = routeGjson
      } else {
        this.helper.openQuickSnackBar("Bus route not available.","Ok")
      }
    }
    this.showRoute = true;
  }
  getStopsData(data,routeData){
    this.busStopLists=[];
    // this.http.get('assets/'+getname+'.geojson').subscribe(data => {
      if(data['features'] && data['features'].length >0){
        data['features'].map((feature: any,index)=>{
          if(feature.geometry.type === "Point"){
            var busObj = {
              lat: feature.geometry.coordinates[1],
              lng: feature.geometry.coordinates[0],
              order: feature.properties.order,
              name: feature.properties.name
            }
            this.busStopLists.push(busObj);
          }
        })
        if(this.busStopLists[0] && this.busStopLists[0].lat){
          this.lat = this.busStopLists[0].lat;
          this.lng = this.busStopLists[0].lng;
          this.zoom = 14
        } else {
          this.helper.openSnackBar("Bus stop not available.","ok")
        }
      }
    // });
  }
  lineStringStyle(feature: any): any {
    const strokeColor = feature.getProperty('stroke');
    const highway = feature.getProperty('highway');
    const strokeOpacity = feature.getProperty('strokeOpacity');
    
    // #b99885 brown
    return {
      strokeColor: strokeColor?strokeColor:'#1913E6',
      // highway === 'secondary'?'#b99885':highway === 'residential'?'#f1772e':strokeColor, // Change stroke color to blue
      strokeWeight: 10,
      strokeOpacity: strokeOpacity?strokeOpacity:0.7
    };
  }
  uploadFile(){
    this.routeGjson = {};
    this.busStopLists=[];
    this.busReport = {};
    this.showUpload = true;
    this.showNewRoute = true;
  }
  cancelUpload(){
    this.showUpload = false;
    this.showNewRoute = false;
    this.showMapTruck = false;
    this.getTransportRoutes();
  }
  fileChangeEvent(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        const result = reader.result as string;
        const jsonData = JSON.parse(result);
        
        console.log(jsonData);
        this.newRoute['geojson'] = jsonData; // You can use jsonData in your application
        this.splitStreetsFromGeojson(jsonData, {});
      this.getStopsData(jsonData, {});
      };

      reader.onerror = () => {
        console.error('Error occurred while reading file');
      };
    }
  }
  async createRoute(){
    console.log(this.newRoute)
    var url = 'transport?orgId='+this.orgId+'&routeColor='+encodeURIComponent(this.newRoute['color'])+'&portalId='+this.newRoute['portalId']+'&routeName='+this.newRoute['name'];
    this.apiService.postNewdataWithQueryParams(url, this.newRoute['geojson']).subscribe(
      (res: any) => {
        console.log(res);
        this.helper.openSnackBar("Route created successfully", "OK")
        this.newRoute['name'] = '';
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async saveRoute(){
    var url = 'transport?mapTransportRouteBus=true';
    var busIds = []
    this.selectedTruck.map((truck)=> {
      busIds.push(truck.Id)
    })
    var payload = {
      "orgId":Number(this.orgId),
      "portalId":this.newRoute['portalId'],
      "transportRouteId":this.selectedRoute.Id,
      "busIds":busIds
    }
    this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        this.helper.openSnackBar("Bus assigned successfully", "OK")
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getPortals() {
    var url = 'portal?orgId=' + this.orgId;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'].filter((portal)=>{
          return portal.IsTransportHub
        });
        this.newRoute['portalId'] = this.allPortals[0].Id;
        this.getTransportRoutes();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getTransportRoutes() {
    var url = 'transport?orgId='+this.orgId+'&portalId=' + this.newRoute['portalId'];
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.transportRoutesList = res;
        this.showMap = true;
        this.getTruckTypes();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getTruckTypes() {
    var url = 'truckType?orgId=' + this.orgId + '&portalId=' + Number(this.newRoute['portalId']);
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.trucksTypesArray = data;
      this.selectedTruckType = data[0]['Id']
      this.getTrucks();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getTrucks() {
    var url = 'truck?orgId=' + this.orgId + '&portalId=' + this.newRoute['portalId'] + '&truckType=' + this.selectedTruckType
    +'&includeInactiveTrucks=false';
    this.dropdownSettings = {
      idField: 'Id',
      textField: 'Description',
      enableCheckAll: false,
      allowSearchFilter: true,
      singleSelection: false
  };
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.trucksArray = data;
      this.selectedTruck
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  getportalId(portalId) {
    console.log(portalId);
    this.selectedPortal = portalId;
    this.newRoute['portalId'] = portalId
  }
  showMapRouteForm(selectedroute){
    this.selectedRoute = selectedroute;
    this.showUpload = true;
    this.showMapTruck = true;
  }
  async mapRoute(getRoute: any){
    var url = 'transport?orgId='+this.orgId+'&portalId=' + this.newRoute['portalId'];
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.transportRoutesList = res;
        this.showMap = true;
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  addSelected(key: any, value: any) {
    this.selectedTruckType = value;
      this.getTrucks()
  }
  dalaLayerClicked(event: MouseEvent){
    console.log(event)
    var clickedLatLng = {
      lat: event['latLng'].lat(),
      lng: event['latLng'].lng()
    };
    console.log(clickedLatLng.lat+','+clickedLatLng.lng)
  }
  onTruckSelect(selectedTruck: any){
    console.log(selectedTruck);
    // this.selectedTruck = [];
    // this.selectedTruck.push(selectedTruck)
  }
  getbusStopDetails(busStopDetails, i){
    console.log(busStopDetails)
    var url = 'transport?findBusArrivalTime=true';
    var busIds = []
    this.selectedTruck.map((truck)=> {
      busIds.push(truck.Id)
    })
    var payload = {
      "orgId":Number(this.orgId),
      "portalId":this.newRoute['portalId'],
      "transportRouteId":busStopDetails.TransportRouteId,
      "stopId":busStopDetails.Id
    }
    this.selectedBusStopDetails = {};
    this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        if(Object.keys(res).length !== 0){
          this.busStopLists[i]['eta'] = Object.entries(res);
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
}
