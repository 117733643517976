import { NgModule , CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule, MatToolbarModule, MatIconModule, MatButtonModule, MatMenuModule, MatListModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AreaComponent } from './widgets/area/area.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { CardComponent } from './widgets/card/card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { PieComponent } from './widgets/pie/pie.component';
import { FilterComponent } from './components/filter/filter.component';
import { CalendarModule } from 'primeng-lts/calendar';
import { MessagesModule } from 'primeng-lts/messages';
import { MessageModule } from 'primeng-lts/message';
import { DialogModule } from 'primeng-lts/dialog';
import { ConfirmDialogModule } from 'primeng-lts/confirmdialog';
import { ConfirmationService } from 'primeng-lts/api';
import { PaginatorModule } from 'primeng-lts/paginator';
import { TableModule } from 'primeng-lts/table';
import { MultiSelectModule } from 'primeng-lts/multiselect';
import { DropdownModule } from 'primeng-lts/dropdown';
import { TooltipModule } from 'primeng-lts/tooltip';
import { SidebarModule } from 'primeng-lts/sidebar';
import {SplitButtonModule} from 'primeng-lts/splitbutton';
import { DatePipe } from '@angular/common';
import {MessageService} from 'primeng-lts/api';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AngularMaterialModule } from '../angular-material.module';
import { WebcamModule } from 'ngx-webcam';
import { ImageDragDirective } from './directives/image-drag.directive';
import { EditComponent } from './components/edit/edit.component';
import { OverlayPanelModule } from 'primeng-lts/overlaypanel';
import { ColorPickerModule } from 'smart-webcomponents-angular/colorpicker';
// import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { ColorSketchModule } from 'ngx-color/sketch';
import { EditorModule } from 'primeng-lts/editor';
import { MarkerClusterDirective } from './directives/marker-cluster.directive';
import { QRCodeModule } from 'angularx-qrcode';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { BarComponent } from './widgets/bar/bar.component';
import { ConfirmOkDialogComponent } from './components/confirm-ok-dialog/confirm-ok-dialog.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    HeaderComponent,

    FooterComponent,
    SidebarComponent,
    AreaComponent,
    CardComponent,
    PieComponent,
    FilterComponent,
    ConfirmDialogComponent,
    PdfViewerComponent,
    // ImageDragDirective,
    EditComponent,
    MarkerClusterDirective,
    BottomSheetComponent,
    BarComponent,
    ConfirmOkDialogComponent,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    AngularMaterialModule,
    WebcamModule,
    MatMenuModule,
    PdfViewerModule,
    // NgxJsonViewerModule,
    MatListModule,
    RouterModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatDialogModule,
    HighchartsChartModule,
    MatButtonModule,
    CalendarModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    ConfirmDialogModule,
    PaginatorModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    TooltipModule,
    SidebarModule,
    SplitButtonModule,
    OverlayPanelModule,
    ColorPickerModule,
    ColorSketchModule,
    EditorModule,
    QRCodeModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    AreaComponent,
    CardComponent,
    PieComponent,
    FilterComponent,
    EditComponent,
    BarComponent,
    // BottomSheetComponent,
    MarkerClusterDirective
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
  entryComponents: [ConfirmDialogComponent, PdfViewerComponent, EditComponent,BottomSheetComponent, ConfirmOkDialogComponent]
})
export class SharedModule { }
