export const colorConfig = {
    snowpaths : {
        logoName:'SnowPaths-logo',
        publicScreenBackgroud :'#f1772e',
        myLocationbtnColor : '#f1772e',
        headerBackground : '#f1772e',
        footerBackground : '#f1772e',
        loginBtnBackground : '#f1772e',
        thBackground: '#e9ba9e',
        reports :{
            background : 'rgb(253, 235, 224)',
            resetBg: 'rgb(241, 119, 46)',
            printBg: 'white',
            searchBg: 'rgb(221, 92, 15)',
            nowBg:'#dd5c0f',
            labelColor: 'black',
            routeTableHeaderBg:'rgb(233, 186, 158)',
            routeTableHeaderColor:'#6b5548',
            checkBoxContainerClass: 'checkBoxContainer',
            tableRowEvenClass: 'row-orange',
            loadingGifName: 'LoadingTruck'
        },
        alertMessage: {
            saveBackground: 'rgb(221, 92, 15)',
            deleteBackground: 'white',
            deleteColor:'black'
        },
        streetPriority: {
            saveBackground:'rgb(221, 92, 15)'
        },
        status: {
            saveBackground:'rgb(221, 92, 15)'
        },
        publicMapSettings: {
            saveBackground:'rgb(221, 92, 15)'
        },
        logo : {
            saveBackground:'rgb(221, 92, 15)',
        },
        reportChart : {
            background: 'rgb(253, 235, 224)',
            filterBackground : 'rgb(249 217 199)',
            infoBackground: 'rgb(249 217 199)',
            searchBackground: 'rgb(221, 92, 15)',
            labelColor: 'black'
        },
        statistics : {
            filterBackground : 'rgb(249 217 199)',
            background: 'rgb(253, 235, 224)',
            searchBackground: 'rgb(221, 92, 15)'
        }
    },
    streetpaths : {
        logoName:'StreetPathsLogo',
        publicScreenBackgroud :'rgb(12, 38, 73)',
        myLocationbtnColor : 'rgb(247, 205, 71)',
        headerBackground : 'rgb(12, 38, 73)',
        footerBackground : 'rgb(12, 38, 73)',
        loginBtnBackground : 'rgb(12, 38, 73)',
        thBackground: 'rgb(168 171 175)',
        reports :{
            background : 'rgb(62, 87, 130)',
            resetBg: 'rgb(12, 38, 73)',
            printBg: 'rgb(40, 44, 51)',
            searchBg: 'rgb(247, 205, 71)',
            nowBg:'rgb(247, 205, 71)',
            labelColor: 'white',
            routeTableHeaderBg:'rgb(62, 87, 130)',
            routeTableHeaderColor:'white',
            checkBoxContainerClass: 'checkBoxContainerwithBlue',
            tableRowEvenClass: 'row-blue',
            loadingGifName: 'LoadingTruck-StreetPath'
        },
        alertMessage: {
            saveBackground: 'rgb(247, 205, 71)',
            deleteBackground: 'rgb(40, 44, 51)',
            deleteColor:'white'
        },
        streetPriority: {
            saveBackground:'rgb(247, 205, 71)'
        },
        status: {
            saveBackground:'rgb(247, 205, 71)'
        },
        publicMapSettings: {
            saveBackground:'rgb(247, 205, 71)'
        },
        logo : {
            saveBackground:'rgb(247, 205, 71)',
        },
        reportChart : {
            background: '#92bdfa',
            filterBackground : '#eef0f5',
            infoBackground: '#eef0f5',
            searchBackground: 'rgb(247, 205, 71)',
            labelColor: 'black'
        },
        statistics : {
            filterBackground : '#eef0f5',
            background: '#92bdfa',
            searchBackground: 'rgb(247, 205, 71)'
        }
    },
    fleetpaths : {
        logoName:'FleetPathsLogo',
        publicScreenBackgroud :'rgb(12, 38, 73)',
        myLocationbtnColor : 'rgb(247, 205, 71)',
        headerBackground : 'rgb(12, 38, 73)',
        footerBackground : 'rgb(12, 38, 73)',
        loginBtnBackground : 'rgb(12, 38, 73)',
        thBackground: 'rgb(168 171 175)',
        reports :{
            background : 'rgb(62, 87, 130)',
            resetBg: 'rgb(247, 205, 71)',
            printBg: 'rgb(40, 44, 51)',
            searchBg: 'rgb(247, 205, 71)',
            nowBg:'rgb(247, 205, 71)',
            labelColor: 'white',
            routeTableHeaderBg:'rgb(62, 87, 130)',
            routeTableHeaderColor:'white',
            checkBoxContainerClass: 'checkBoxContainerwithBlue',
            tableRowEvenClass: 'row-blue',
            loadingGifName: 'LoadingTruck-StreetPath'
        },
        alertMessage: {
            saveBackground: 'rgb(247, 205, 71)',
            deleteBackground: 'rgb(40, 44, 51)',
            deleteColor:'white'
        },
        streetPriority: {
            saveBackground:'rgb(247, 205, 71)'
        },
        status: {
            saveBackground:'rgb(247, 205, 71)'
        },
        publicMapSettings: {
            saveBackground:'rgb(247, 205, 71)'
        },
        logo : {
            saveBackground:'rgb(247, 205, 71)',
        },
        reportChart : {
            background: '#92bdfa',
            filterBackground : '#eef0f5',
            infoBackground: '#eef0f5',
            searchBackground: 'rgb(247, 205, 71)',
            labelColor: 'black'
        },
        statistics : {
            filterBackground : '#eef0f5',
            background: '#92bdfa',
            searchBackground: 'rgb(247, 205, 71)'
        }
    },
    mower : {
        logoName:'FleetPathsLogo1',
        publicScreenBackgroud :'#718d3c',
        myLocationbtnColor : '#718d3c',
        headerBackground : '#62a384',
        footerBackground : '#62a384',
        loginBtnBackground : '#718d3c',
        thBackground: '#acc88a',
        buttonColorBlack: 'black',
        buttonColorWhite: 'white',
        reports :{
            background : '#18453B',
            resetBg: '#62a384',
            printBg: 'white',
            searchBg: '#62a384',
            nowBg:'#0B9A6D',
            labelColor: 'white',
            routeTableHeaderBg:'white',
            routeTableHeaderColor:'black',
            checkBoxContainerClass: 'checkBoxContainerwithGreen',
            tableRowEvenClass: 'row-green',
            loadingGifName: 'LoadingTruck-StreetPath'
        },
        alertMessage: {
            saveBackground: '#62a384',
            deleteBackground: 'white',
            deleteColor:'black'
        },
        streetPriority: {
            saveBackground:'#62a384'
        },
        status: {
            saveBackground:'#62a384'
        },
        publicMapSettings: {
            saveBackground:'#62a384'
        },
        logo : {
            saveBackground:'#62a384',
        },
        reportChart : {
            background: '#18453B',
            filterBackground : '#0B9A6D',
            infoBackground: '#0B9A6D',
            searchBackground: '#62a384',
            labelColor: 'white'
        },
        statistics : {
            filterBackground : '#0B9A6D',
            background: '#18453B',
            searchBackground: '#62a384'
        }
    }
}