import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) {
    }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Handle unauthorized errors, e.g., redirect to login page
          console.error('Unauthorized Error:', error);
          // Redirect to the login page
          // this.router.navigate(['/login']);
        } else if(error.error === "Authentication failed.Invalid token"){
            this.router.navigate(['login']);
            window.location.reload()
        }else{
          // Handle other errors
          console.error('API Error:', error);
          // You can show a user-friendly error message here
        }

        // Pass the error to the calling code
        return throwError(error);
      })
    );
  }
}