import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/api/helper.service';
import { ApiService } from 'src/app/api/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  sideBarOpen = false;
  id:any;
  backendVersion:any;

  constructor(private apiService: ApiService, private helper: HelperService) { }

  ngOnInit() {
    if (this.id) {
      clearInterval(this.id);
    }
    var authResult = this.helper.isAuthenticated();
    if(authResult){
    this.getVersion
    this.id = setInterval(() => {
        if(this.helper.isAuthenticated()){
          this.getVersion()
        }
      }, 60000);
    }
  }
  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }
  async getVersion() {
    var url = 'version';
    if(!this.apiService.isPendingResponse){
    await this.apiService.getVersion(url).subscribe((data: any) => {
      if(data){
        this.backendVersion = data;
        this.apiService.addLocalStorage('Backend', data['Title'])
        this.apiService.addLocalStorage('BackendVersion', data['Version'])
      }
    }, (error) => {
      console.log(error);
      this.getVersion
    })
  }
  }

  isAuthenticated() {
    let res = this.helper.isAuthenticated();
    return this.helper.isAuthenticated();
    // return true;
  }

  isAuthTypeCognito() {
    return environment.authType == 'cognito';
  }

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }

}
